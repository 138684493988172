import { Navigate, Outlet, useLocation } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signOut } from "firebase/auth";
import { Box, Flex } from "@chakra-ui/layout";
import { CircularProgress } from "@chakra-ui/progress";
import { useDisclosure } from "@chakra-ui/hooks";
import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/modal";
import SidebarContent from "./sidebar-content";
import { IconButton } from "@chakra-ui/button";
import { FiMenu } from "react-icons/fi";
import Container from "./container";
import { useAuth } from "../store/auth.store";

function DashboardLayout() {
  const [user, loading, error] = useAuthState(getAuth());
  const { user: inlineUser } = useAuth();
  const sidebar = useDisclosure();
  let location = useLocation();

  if (loading || inlineUser === undefined) {
    return (
      <Flex align="center" justify="center" minW="100vh" minH="100vh">
        <CircularProgress isIndeterminate color="green.300" />
      </Flex>
    );
  }

  if (!user || error) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (!user && !inlineUser) {
    signOut().then(() => {
      <Navigate to="/login" />;
    });
  }

  return (
    <>
      <Box as="section" minH="100vh">
        <SidebarContent display={{ base: "none", md: "unset" }} />
        <Drawer
          isOpen={sidebar.isOpen}
          onClose={sidebar.onClose}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent>
            <SidebarContent w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>
        <Container>
          <Box
            ml={{ base: 0, md: 72 }}
            px={{ base: 0, lg: 8 }}
            py={{ base: 0, lg: 14 }}
            transition=".3s ease"
          >
            <Flex
              as="header"
              align="center"
              justify="space-between"
              w="full"
              px="4"
              bg="purple.900"
              borderBottomWidth="1px"
              borderColor="blackAlpha.300"
              h="14"
              d={{ base: "flex", md: "none" }}
            >
              <IconButton
                aria-label="Menu"
                display={{ base: "inline-flex", md: "none" }}
                onClick={sidebar.onOpen}
                icon={<FiMenu />}
                colorScheme="purple"
                size="sm"
              />
            </Flex>

            <Box as="main" p="4">
              <Outlet />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default DashboardLayout;
