import {
  Box,
  Flex,
  Heading,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { getApiLogs } from "../../services/api-key.service";
import Table from "../../elements/table";
import { useMemo } from "react";

function ApiKeyLogs() {
  const { apiKey } = useParams();

  const { data, isLoading } = useQuery(
    ["getApiKeys", apiKey],
    () => getApiLogs(apiKey),
    {
      cacheTime: 0,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: "Endpoint",
        accessor: "endpoint",
      },
      {
        Header: "Method",
        accessor: "method",
      },
      {
        Header: "Referrer IP",
        accessor: "referrer",
      },
      {
        Header: "Timestamp",
        accessor: "timestamp",
        Cell: ({ value }) => {
          return dayjs(value).format("DD MMM YYYY, HH:mm");
        },
        otherProps: { textAlign: "right", maxW: 100 },
      },
    ],
    []
  );

  if (isLoading) {
    return <p>data is loading...</p>;
  }

  return (
    <Flex flexDir="column">
      <Heading size="md">Api Key Details</Heading>
      <VStack align="flex-start" w="full" spacing="4">
        <Box w="full">
          <StatGroup
            p="4"
            borderRadius="lg"
            border="1px"
            borderColor="gray.100"
            boxShadow="sm"
            flexDirection="column"
            mt="4"
          >
            <Stat size="sm" mb="4">
              <StatLabel>Key</StatLabel>
              <StatNumber>{apiKey}</StatNumber>
            </Stat>
            <Stat size="sm" mb="4">
              <StatLabel>Owner Name</StatLabel>
              <StatNumber>{data.meta.owner}</StatNumber>
            </Stat>
            <Stat size="sm" mb="4">
              <StatLabel>Contact Address</StatLabel>
              <StatNumber>{data.meta.contactAddress}</StatNumber>
            </Stat>
            <Stat size="sm">
              <StatLabel>Created At</StatLabel>
              <StatNumber>
                {dayjs(data.created_at).format("DD MMM YYYY, HH:mm")}
              </StatNumber>
            </Stat>
          </StatGroup>
        </Box>
        {data.logs?.length > 0 ? (
          <Box w="full">
            <Table columns={columns} data={data.logs || []} />
          </Box>
        ) : (
          <p>No logs found</p>
        )}
      </VStack>
    </Flex>
  );
}

export default ApiKeyLogs;
