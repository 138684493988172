import Icon from "@chakra-ui/icon";
import { Flex } from "@chakra-ui/layout";
import { Link as RouterLink, useLocation } from "react-router-dom";

function NavItem({ icon, children, href, ...rest }) {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <RouterLink to={href}>
      <Flex
        align="center"
        px="4"
        mx="2"
        rounded="md"
        py="3"
        cursor="pointer"
        _hover={{ bg: "purple.800", color: "gray.200" }}
        transition=".15s ease"
        color={isActive ? "purple.50" : "purple.300"}
        bg={isActive ? "purple.800" : "transparent"}
        mb="2"
        {...rest}
      >
        {icon && <Icon mr="2" boxSize="22px" as={icon} />}
        {children}
      </Flex>
    </RouterLink>
  );
}

export default NavItem;
