import axios from "axios";
import { herokuURL } from "../config";

export const getAdmins = async () => {
  const { data } = await axios.get(`${herokuURL}/user/list-admins`);

  return data;
};

export const makeUserAdmin = async (payload) => {
  const { data } = await axios.post(`${herokuURL}/user/adminize`, payload);

  return data;
};

export const removeUserAdmin = async (payload) => {
  const { data } = await axios.post(
    `${herokuURL}/user/remove-adminize`,
    payload
  );

  return data;
};
