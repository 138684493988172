import { Box, Flex } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";

const Skeletons = () => {
  return (
    <Box>
      <Skeleton mt={1} mb={1} w="full" h="28px" />
      <Flex>
        <Skeleton mt={1} mb={1} mr={2} _last={{ mr: 0 }} w="full" h="24px" />
        <Skeleton mt={1} mb={1} mr={2} _last={{ mr: 0 }} w="full" h="24px" />
        <Skeleton mt={1} mb={1} mr={2} _last={{ mr: 0 }} w="full" h="24px" />
        <Skeleton mt={1} mb={1} mr={2} _last={{ mr: 0 }} w="full" h="24px" />
      </Flex>
    </Box>
  );
};

const TableSkeleton = ({ count = 8 }) => {
  const arr = Array.from(Array(count).keys());
  return (
    <Box pos="absolute" top="0" w="full" minH="400">
      {arr.map((number) => (
        <Skeletons key={number} />
      ))}
    </Box>
  );
};

export default TableSkeleton;
