import { Box, Flex } from "@chakra-ui/layout";
import {
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from "@chakra-ui/table";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useEffect, useRef } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { usePagination, useSortBy, useTable } from "react-table";
import TableSkeleton from "./table-skeleton";

function Table({ id, data, columns, initialState, rowProps }) {
  const rowBG = useColorModeValue("white", "gray.900");
  const scrollableContainer = useRef();

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 100, pageIndex: 0, ...initialState },
      disableSortRemove: true,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  const {
    headerGroups,
    prepareRow,
    state: tableState,
    page,
    getTableProps,
    getTableBodyProps,
    setSortBy,
  } = tableInstance;

  useEffect(() => {
    setSortBy(tableState.sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box pos="relative">
      <Box
        id={id}
        ref={scrollableContainer}
        overflowY="hidden"
        overflowX="auto"
      >
        <ChakraTable fontSize="sm" fontWeight="500" {...getTableProps()}>
          <Thead as="thead" borderRadius="md" bg={rowBG} boxShadow="sm">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps({})}>
                {headerGroup.headers.map((column) => {
                  const {
                    id,
                    isSorted,
                    getHeaderProps,
                    getSortByToggleProps,
                    isSortedDesc,
                  } = column;

                  return (
                    <Th
                      bg={rowBG}
                      px={2}
                      fontSize="sm"
                      textTransform="unset"
                      letterSpacing="unset"
                      whiteSpace="nowrap"
                      {...getHeaderProps(
                        getSortByToggleProps({
                          title: column.title || column.Header,
                        })
                      )}
                      verticalAlign="middle"
                      {...column.otherProps}
                    >
                      <Flex d="inline-flex" align="center">
                        {column.render("Header")}
                        {id !== "favorite" && isSorted && (
                          <Box
                            ml="2"
                            d="inline"
                            as={isSortedDesc ? FiChevronUp : FiChevronDown}
                            boxSize="16px"
                          />
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody as="tbody" className="body" {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  mb={2}
                  borderRadius="md"
                  bg={rowBG}
                  py={2}
                  {...row.getRowProps()}
                  {...rowProps}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        bg={rowBG}
                        px={2}
                        alignItems="center"
                        w={cell.column.customWidth}
                        {...cell.getCellProps()}
                        {...cell.column.otherProps}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
        {/* <TableSkeleton /> */}
      </Box>
      {(!data || data.length === 0) && <TableSkeleton />}
    </Box>
  );
}

export default Table;
