import { getApps, initializeApp } from "firebase/app";
import { isProduction } from "../config";

let firebaseConfig;

if (isProduction) {
  firebaseConfig = {
    apiKey: "AIzaSyBLJc3lHHbZc6KunA-oNh3gkRmebln5Z30",
    authDomain: "fmc-27374.firebaseapp.com",
    projectId: "fmc-27374",
    storageBucket: "fmc-27374.appspot.com",
    messagingSenderId: "854459747204",
    appId: "1:854459747204:web:f3ecd7fddc641a61deabd1",
    measurementId: "G-0T2QFQ2ENE",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyAX_pS5G8rUWn0UPDxx-gpAVN3Ovcd89hw",
    authDomain: "fanmarketcap.firebaseapp.com",
    projectId: "fanmarketcap",
    storageBucket: "fanmarketcap.appspot.com",
    messagingSenderId: "730698902568",
    appId: "1:730698902568:web:7f39a23c548fcf6ae0d91d",
    measurementId: "G-5DGBEWS3G4",
  };
}

if (typeof window !== "undefined" && !getApps().length) {
  initializeApp(firebaseConfig);
}
