import React from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Checkbox,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { useField } from "formik";
import { RiInformationFill } from "react-icons/ri";

const Input = ({
  label,
  component,
  children,
  labelColor,
  description,
  required,
  ...props
}) => {
  const [field, meta] = useField(props);

  const isInvalid = meta.touched && meta.error;

  return (
    <FormControl
      isRequired={required}
      isInvalid={isInvalid || false}
      w="full"
      mb="4"
    >
      {label && (
        <FormLabel
          d="inline-flex"
          htmlFor={field.name}
          color={labelColor || "gray.600"}
          alignItems="center"
        >
          {label}
          {description && (
            <Tooltip label={description} p="4" borderRadius="lg">
              <Box ml="1" color="gray.400" cursor="pointer">
                <Box boxSize="22px" as={RiInformationFill} />
              </Box>
            </Tooltip>
          )}
        </FormLabel>
      )}
      <Checkbox
        id={field.name}
        name={field.name}
        aria-label={label || field.name}
        aria-describedby={label || field.name}
        type="checkbox"
        isInvalid={isInvalid}
        isChecked={field.value}
        {...field}
        {...props}
      >
        {children}
      </Checkbox>
      {isInvalid ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default Input;
