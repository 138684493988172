import { Badge, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";

const BasicTokenName = ({ logo, name, symbol, containerProps }) => {
  const badgeScheme = useColorModeValue("blackAlpha", "gray");
  return (
    <Flex
      w="full"
      maxW="2xs"
      mr="4"
      align="center"
      mb={{ base: "4", md: "0" }}
      justify="flex-start"
      {...containerProps}
    >
      <Flex position="relative" width="32px" h="32px" flexShrink={0}>
        <Image w="32px" h="32px" src={logo} alt={name} />
      </Flex>
      <Flex align="center" ml="3" w="full" minW="0" maxW="140px">
        <Text fontSize="sm" fontWeight="600" noOfLines={2}>
          {name}
        </Text>
        <Badge ml="2" colorScheme={badgeScheme}>
          {symbol}
        </Badge>
      </Flex>
    </Flex>
  );
};

export default BasicTokenName;
