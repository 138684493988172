import { Alert, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Box, Flex, Heading, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { object, string } from "yup";
import Input from "../../elements/input";
import {
  getAdmins,
  makeUserAdmin,
  removeUserAdmin,
} from "../../services/admin.service";
import Table from "../../elements/table";
import { useMemo } from "react";
import dayjs from "dayjs";

const apiKeySchema = object().shape({
  uid: string().email().required("User email is required"),
});

const initialValues = {
  uid: "",
};

export function MakeAdmin() {
  const toast = useToast();
  const navigate = useNavigate();

  const makeMutation = useMutation("makeUserAdmin", makeUserAdmin);
  const getAdminsQuery = useQuery("getAdmins", getAdmins, { enabled: false });
  const removeMutation = useMutation("removeUserAdmin", removeUserAdmin);

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Name",
        accessor: "displayName",
      },
      {
        Header: "Last sign in",
        accessor: "metadata",
        Cell: ({ value }) => {
          return dayjs(value.lastSignInTime).format("DD MMM YYYY, HH:mm");
        },
        otherProps: { textAlign: "right", maxW: 100 },
      },
    ],
    []
  );

  return (
    <Stack gap="16px">
      <Box>
        <Heading fontSize="lg">List admins</Heading>
        <Box mt="4">
          <Button
            isLoading={getAdminsQuery.isLoading}
            isDisabled={getAdminsQuery.isLoading}
            colorScheme="blue"
            onClick={() => getAdminsQuery.refetch()}
          >
            List them all
          </Button>
        </Box>
        {getAdminsQuery.data && (
          <Box mt="4">
            <Table columns={columns} data={getAdminsQuery.data || []} />
          </Box>
        )}
      </Box>
      <Box>
        <Heading fontSize="lg">Make admin</Heading>

        <Box mt="6">
          <Formik
            initialValues={initialValues}
            validationSchema={apiKeySchema}
            onSubmit={async (values) => {
              await makeMutation.mutateAsync(values).then(() => {
                toast({
                  title: "User is now admin",
                  description: `We made user admin successfully.`,
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                  position: "top",
                });
                navigate("/dashboard");
              });
            }}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Input
                  name="uid"
                  label="Email"
                  placeholder="Enter user email"
                />

                <Flex alignItems="center" justify="flex-end">
                  <Button
                    isLoading={makeMutation.isLoading}
                    isDisabled={!isValid || makeMutation.isLoading || !dirty}
                    type="submit"
                    colorScheme="blue"
                  >
                    Add
                  </Button>
                </Flex>
                {makeMutation.error && (
                  <Alert
                    flexWrap="wrap"
                    variant="solid"
                    borderRadius="lg"
                    mr="4"
                    status="error"
                    mt="4"
                  >
                    <AlertIcon mb="4" />
                    {makeMutation.error.response?.data.message}
                  </Alert>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <Box>
        <Heading fontSize="lg">Remove admin</Heading>

        <Box mt="6">
          <Formik
            initialValues={initialValues}
            validationSchema={apiKeySchema}
            onSubmit={async (values) => {
              await removeMutation.mutateAsync(values).then(() => {
                toast({
                  title: "User is not admin anymore",
                  description: `We've changed user admin status successfully.`,
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                  position: "top",
                });
                navigate("/dashboard");
              });
            }}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Input
                  name="uid"
                  label="Email"
                  placeholder="Enter user email"
                />

                <Flex alignItems="center" justify="flex-end">
                  <Button
                    isLoading={removeMutation.isLoading}
                    isDisabled={!isValid || removeMutation.isLoading || !dirty}
                    type="submit"
                    colorScheme="blue"
                  >
                    Remove
                  </Button>
                </Flex>
                {removeMutation.error && (
                  <Alert
                    flexWrap="wrap"
                    variant="solid"
                    borderRadius="lg"
                    mr="4"
                    status="error"
                    mt="4"
                  >
                    <AlertIcon mb="4" />
                    {removeMutation.error.response?.data.message}
                  </Alert>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Stack>
  );
}
