import axios from "axios";
import { herokuURL } from "../config";

export const getTokensWithoutMarket = async () => {
  const { data } = await axios.get(`${herokuURL}/tokens`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const getTokenWithoutMarket = async (slug) => {
  const { data } = await axios.get(`${herokuURL}/token/${slug}`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const createToken = async (values) => {
  const { data } = await axios.post(`${herokuURL}/tokens`, values);

  return data;
};

export const updateToken = async (values, slug) => {
  const { data } = await axios.patch(`${herokuURL}/tokens/${slug}`, values);

  return data;
};

export const deleteToken = async (slug) => {
  const { data } = await axios.delete(`${herokuURL}/token/${slug}`);

  return data;
};

export const getMarketInfo = async () => {
  const { data } = await axios.get(`${herokuURL}/markets/usd`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const getFMCInfo = async () => {
  const { data } = await axios.get(`${herokuURL}/stats`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const getCurrencies = async () => {
  const { data } = await axios.get(`${herokuURL}/currencies`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};
