import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Tooltip,
  useToast,
  Badge,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { RiAddBoxFill, RiDeleteBin2Fill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import Table from "../../elements/table";
import { deleteApiKey, getApiKeys } from "../../services/api-key.service";

function APIKeys() {
  const toast = useToast();
  const { data, refetch } = useQuery("getApiKeys", getApiKeys, {
    cacheTime: 0,
  });
  const { mutateAsync } = useMutation("deleteApiKey", deleteApiKey);

  const columns = useMemo(
    () => [
      {
        Header: "Plan",
        accessor: "plan",
      },
      {
        Header: "Owner",
        accessor: "meta.owner",
      },
      {
        Header: "Key",
        accessor: "key",
        Cell: ({ value }) => {
          return (
            <Button
              as={Link}
              to={value}
              ml="4"
              variant="link"
              colorScheme="blue"
              size="sm"
            >
              {value}
            </Button>
          );
        },
      },
      {
        Header: "Usage",
        accessor: "usage",
        Cell: ({ value }) => {
          const currentYear = dayjs().year();
          const currentMonth = dayjs().month();
          const usageValue = value?.[currentYear]?.[currentMonth];

          if (usageValue) {
            return (
              <Box>
                {dayjs().format("MMM YYYY")} usage is: <br />
                <Badge>{usageValue}</Badge>
              </Box>
            );
          }

          return "Data not available yet. Probably no usage yet.";
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => {
          return dayjs(value).format("DD MMM YYYY, HH:mm");
        },
        otherProps: { textAlign: "right", maxW: 100 },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ value }) => {
          return (
            <HStack justify="flex-end">
              <Tooltip hasArrow placement="top" label="Delete" bg="red.400">
                <IconButton
                  size="sm"
                  variant="solid"
                  colorScheme="red"
                  icon={<RiDeleteBin2Fill />}
                  onClick={async () => {
                    await mutateAsync(value)
                      .then((data) => {
                        toast({
                          position: "top-right",
                          title: "Api Key deleted successfully",
                          status: "success",
                        });

                        refetch();
                      })
                      .catch((err) => {
                        console.log(err);
                        toast({
                          position: "top-right",
                          title: "Something went wrong",
                          status: "error",
                        });
                      });
                  }}
                />
              </Tooltip>
            </HStack>
          );
        },
        otherProps: { textAlign: "right" },
      },
    ],
    [mutateAsync, refetch, toast]
  );

  return (
    <Box>
      <Flex align="center">
        <Heading fontSize="lg">API Keys</Heading>
        <Button
          as={Link}
          to="create"
          ml="4"
          variant="solid"
          size="sm"
          leftIcon={<RiAddBoxFill />}
        >
          Create new key
        </Button>
      </Flex>
      <Box mt="4">
        <Table columns={columns} data={data || []} />
      </Box>
    </Box>
  );
}

export default APIKeys;
