import { Box, Flex, Heading } from "@chakra-ui/layout";
import dayjs from "dayjs";
import { useMemo } from "react";
import { RiArrowDownLine, RiArrowUpLine } from "react-icons/ri";
import { useQuery } from "react-query";
import BasicTokenName from "../../elements/basic-token-name";
import Table from "../../elements/table";
import { getPriceAlerts } from "../../services/price-alert.service";
import { formatPrice } from "../../utils";

function PriceAlerts() {
  const { data } = useQuery("getTokens", getPriceAlerts);

  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "user.username",
      },
      {
        Header: "Fan Token",
        accessor: "token",
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <BasicTokenName
                logo={value.logo}
                name={value.name}
                symbol={row.original.symbol}
              />
            );
          }

          return null;
        },
      },
      {
        Header: "Direction",
        accessor: "direction",
        Cell: ({ value }) => {
          if (value === "up") {
            return <RiArrowUpLine size="20px" />;
          } else {
            return <RiArrowDownLine size="20px" />;
          }
        },
      },
      {
        Header: "Target Value",
        accessor: "value",
        Cell: ({ value, row }) => {
          return formatPrice(value);
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => {
          return dayjs(value).format("DD MMM YYYY, HH:mm");
        },
        otherProps: { textAlign: "right", maxW: 100 },
      },
    ],
    []
  );

  return (
    <Box>
      <Flex align="center">
        <Heading fontSize="lg">Price Alerts</Heading>
      </Flex>
      <Box mt="4">
        <Table
          columns={columns}
          data={data || []}
          initialState={{
            sortBy: [{ id: "created_at", desc: true }],
          }}
        />
      </Box>
    </Box>
  );
}

export default PriceAlerts;
