import { Button } from "@chakra-ui/button";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/stat";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  checkAndUpdatePartnerIDs,
  checkAndUpdateSurveys,
  runCalculateSupply,
  runCalculateTotalSupply,
  runUpdateTeam,
  runUpdateTokenPrices,
} from "../../services/cron.service";
import { getFMCInfo, getMarketInfo } from "../../services/token.service";
import { formatPrice } from "../../utils";

function Dashboard() {
  const { data: marketData } = useQuery("getMarketInfo", getMarketInfo);
  const { data: fmcData } = useQuery("getFMCInfo", getFMCInfo);
  const tokenPricesMutation = useMutation(runUpdateTokenPrices);
  const tokenCalculateTotalSupplyMutation = useMutation(
    runCalculateTotalSupply
  );
  const calculateSupplyMutation = useMutation(runCalculateSupply);
  const teamMutation = useMutation(runUpdateTeam);
  const checkAndUpdatePartnerIDsMutation = useMutation(
    checkAndUpdatePartnerIDs
  );
  const checkAndUpdateSurveysMutation = useMutation(checkAndUpdateSurveys);

  return (
    <Box>
      <Heading>Welcome</Heading>
      <Box my="4">
        <Heading mb="4" fontSize="md">
          FMC Information
        </Heading>
        <StatGroup
          p="4"
          borderRadius="lg"
          border="1px"
          borderColor="gray.100"
          boxShadow="sm"
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Stat>
            <StatLabel>User</StatLabel>
            <StatNumber>{fmcData?.user}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Active Price Alert</StatLabel>
            <StatNumber>{fmcData?.priceAlert}</StatNumber>
          </Stat>
        </StatGroup>
      </Box>

      <Box my="4">
        <Heading mb="4" fontSize="md">
          Market Information
        </Heading>
        <StatGroup
          p="4"
          borderRadius="lg"
          border="1px"
          borderColor="gray.100"
          boxShadow="sm"
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Stat>
            <StatLabel>Fan Token Count</StatLabel>
            <StatNumber>{marketData?.tokens}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Market Cap</StatLabel>
            <StatNumber>{formatPrice(marketData?.market_cap)}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Volume</StatLabel>
            <StatNumber>{formatPrice(marketData?.volume)}</StatNumber>
          </Stat>
        </StatGroup>
      </Box>

      <Box>
        <Heading mb="4" fontSize="md">
          Actions
        </Heading>
        <VStack>
          <Button
            as={Link}
            to="fan-tokens/create"
            justifyContent="flex-start"
            colorScheme="green"
            w="full"
            fontWeight="500"
            whiteSpace="unset"
          >
            Create new token
          </Button>
          <Button
            to="fan-tokens/create"
            justifyContent="flex-start"
            colorScheme="yellow"
            w="full"
            fontWeight="500"
            isLoading={tokenPricesMutation.isLoading}
            isDisabled={tokenPricesMutation.isLoading}
            onClick={() => tokenPricesMutation.mutate()}
            whiteSpace="unset"
          >
            Run "update token prices" cron job
          </Button>
          <Button
            justifyContent="flex-start"
            colorScheme="yellow"
            w="full"
            fontWeight="500"
            isLoading={calculateSupplyMutation.isLoading}
            isDisabled={calculateSupplyMutation.isLoading}
            onClick={() => calculateSupplyMutation.mutate()}
            whiteSpace="unset"
          >
            Run "calculate supply" cron job
          </Button>
          <Button
            justifyContent="flex-start"
            colorScheme="yellow"
            w="full"
            fontWeight="500"
            isLoading={tokenCalculateTotalSupplyMutation.isLoading}
            isDisabled={tokenCalculateTotalSupplyMutation.isLoading}
            onClick={() => tokenCalculateTotalSupplyMutation.mutate()}
            whiteSpace="unset"
          >
            Run "update total supply" cron job
          </Button>
          <Button
            justifyContent="flex-start"
            colorScheme="yellow"
            w="full"
            fontWeight="500"
            isLoading={teamMutation.isLoading}
            isDisabled={teamMutation.isLoading}
            onClick={() => teamMutation.mutate()}
            whiteSpace="unset"
            textAlign="left"
            py={{ base: "8", lg: "4" }}
          >
            Run "update team standings and fixtures" cron job
          </Button>
          <Button
            justifyContent="flex-start"
            colorScheme="yellow"
            w="full"
            fontWeight="500"
            isLoading={checkAndUpdatePartnerIDsMutation.isLoading}
            isDisabled={checkAndUpdatePartnerIDsMutation.isLoading}
            onClick={() => checkAndUpdatePartnerIDsMutation.mutate()}
            whiteSpace="unset"
            textAlign="left"
            py={{ base: "8", lg: "4" }}
          >
            Run partner id's cron job
          </Button>
          <Button
            justifyContent="flex-start"
            colorScheme="yellow"
            w="full"
            fontWeight="500"
            isLoading={checkAndUpdateSurveysMutation.isLoading}
            isDisabled={checkAndUpdateSurveysMutation.isLoading}
            onClick={() => checkAndUpdateSurveysMutation.mutate()}
            whiteSpace="unset"
            textAlign="left"
            py={{ base: "8", lg: "4" }}
          >
            Run surveys cron job
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}

export default Dashboard;
