import axios from "axios";
import { herokuURL } from "../config";

export const runUpdateTokenPrices = async () => {
  const { data } = await axios.get(`${herokuURL}/markets/update`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const runCalculateSupply = async () => {
  const { data } = await axios.get(`${herokuURL}/markets/calculateSupply`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const runCalculateTotalSupply = async () => {
  const { data } = await axios.get(`${herokuURL}/markets/calculateChilizTotalSupply`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const runUpdateTeam = async () => {
  const { data } = await axios.patch(`${herokuURL}/team/updateAll`);

  return data;
};

export const checkAndUpdatePartnerIDs = async () => {
  const { data } = await axios.patch(
    `${herokuURL}/surveys/checkAndUpdatePartnerIDs`
  );

  return data;
};

export const checkAndUpdateSurveys = async () => {
  const { data } = await axios.patch(
    `${herokuURL}/surveys/checkAndUpdateSurveys`
  );

  return data;
};
