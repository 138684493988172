import { Alert, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Box, Code, Flex, Heading, HStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Field, FieldArray, Form, Formik } from "formik";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import { object, string, array } from "yup";
import AlertDialog from "../../elements/alert-dialog";
import ImageUploader from "../../elements/image-uploader";
import Input from "../../elements/input";
import NumberInput from "../../elements/number-input";
import Checkbox from "../../elements/checkbox";
import { countries } from "../../data";
import {
  createToken,
  deleteToken,
  getCurrencies,
  getTokenWithoutMarket,
  updateToken,
} from "../../services/token.service";
import { removeBearerFromString } from "../../utils";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { SelectField } from "../../elements/combobox";

const tokenSchema = object().shape({
  platform: string().required("Platform is required"),
  name: string().required("Name is required"),
  slug: string().required("Slug is required"),
  symbol: string().required("Symbol is required"),
  logo: string().when("is_active", {
    is: true,
    then: string().required("Logo is required"),
  }),
  fto: object().shape({
    currency: string().required("Currency is required"),
  }),
  homepage: string().url(
    "Homepage should be correct url. ex: https://gal.fantoken.com/"
  ),
  holders: array().of(
    object().shape({
      contract_address: string().required("Required"),
      address: string().required("Required"),
      explorer: string().required("Required"),
    })
  ),
});

const initialValues = {
  platform: "",
  name: "",
  slug: "",
  coingecko: "",
  coinmarketcap: "",
  is_active: false,
  symbol: "",
  description: "",
  contract_address: "",
  address: "",
  total_supply: 0,
  test_supply: 0,
  circulating_supply: 0,
  logo: "",
  homepage: "",
  partner_id: "",
  holders: [],
  country: "",
  fto: {
    price: 0,
    symbol: "",
  },
};

function CreateFanToken({ isEdit = false }) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const {
    data,
    error: getTokenError,
    isLoading,
  } = useQuery(["getToken", slug], () => getTokenWithoutMarket(slug), {
    enabled: isEdit,
    cacheTime: 0,
    staleTime: 0,
  });

  console.log(data);

  const { data: currencies } = useQuery(["getIsoCurrencies"], getCurrencies, {
    placeholderData: [],
  });

  const mutation = useMutation(
    (values) => {
      if (isEdit) {
        return updateToken(values, slug);
      }

      return createToken(values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getToken", slug]);
        queryClient.invalidateQueries("getTokens");
        toast({
          title: isEdit ? "Token updated." : "Token created.",
          description: isEdit
            ? `We've updated ${data.name} successfully.`
            : `We've created the token successfully.`,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        navigate("/dashboard/fan-tokens");
      },
    }
  );

  const deleteTokenMutation = useMutation(deleteToken, {
    onSuccess: () => {
      queryClient.invalidateQueries("getTokens");
      toast({
        title: "Token deleted successfully",
        description: `We've deleted the token successfully.`,
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      navigate("/dashboard/fan-tokens");
    },
  });

  if (isLoading) {
    return <p>loading</p>;
  }

  return (
    <Box>
      <Heading fontSize="lg">
        {isEdit ? `Update ${data?.name || ""}` : "Create New Token"}
      </Heading>
      {getTokenError && (
        <Alert
          flexWrap="wrap"
          variant="solid"
          borderRadius="lg"
          mr="4"
          status="error"
          mt="4"
        >
          <AlertIcon mb="4" />
          There was error while fetching fan token. You can't continue with
          updating process. Send this message to #development channel.
          <Box mt="4">
            <Code wordBreak="break-all">
              {JSON.stringify(getTokenError, null, 2)}
            </Code>
          </Box>
        </Alert>
      )}
      <Box mt="6">
        <Formik
          initialValues={data || initialValues}
          validationSchema={tokenSchema}
          onSubmit={(values) => {
            mutation.mutate(values);
          }}
        >
          {({ isValid, values, dirty }) => (
            <Form>
              <Input
                component="select"
                name="platform"
                label="Platform"
                isDisabled={getTokenError}
                placeholder="Please select a platform"
                required
              >
                <option value="chiliz">Chiliz</option>
                <option value="paribu">Paribu</option>
                <option value="binance">Binance</option>
              </Input>
              <HStack align="flex-start" spacing="6">
                <Input
                  name="name"
                  label="Name"
                  placeholder="Enter token name"
                  required
                  isDisabled={getTokenError}
                />
                <Input
                  name="symbol"
                  label="Symbol"
                  placeholder="Enter token symbol"
                  required
                  isDisabled={getTokenError}
                />
              </HStack>

              <Checkbox name="is_active">Active</Checkbox>
              <ImageUploader
                label="Logo"
                name="logo"
                required={values.is_active}
                isDisabled={getTokenError}
              />
              <Input
                name="slug"
                label="Slug"
                placeholder="Enter token slug"
                required
                isDisabled={getTokenError}
              />
              <HStack align="flex-start" spacing="6">
                <Input
                  name="coingecko"
                  label="Coingecko Slug"
                  placeholder="Enter token's coingecko slug"
                  isDisabled={getTokenError}
                />
                <Input
                  name="coinmarketcap"
                  label="CoinMarketCap Slug"
                  placeholder="Enter token's coinmarketcap slug"
                  isDisabled={getTokenError}
                />
              </HStack>
              <Input
                name="partner_id"
                label="Partner ID"
                placeholder="Enter partner id"
                isDisabled={getTokenError}
              />
              <Input
                component="textarea"
                name="description"
                label="Description"
                placeholder="Enter token description"
                isDisabled={getTokenError}
              />
              <HStack align="flex-start" spacing="6">
                <NumberInput
                  name="fto.price"
                  label="FTO Price"
                  placeholder="Enter token's fto price"
                  isDisabled={getTokenError}
                />
                <Input
                  component="select"
                  name="fto.currency"
                  label="FTO Currency"
                  required
                  placeholder="Select a currency"
                  isDisabled={getTokenError}
                >
                  {currencies.map((currency) => (
                    <option key={currency.code} value={currency.code}>
                      {currency.code.toUpperCase()} - {currency.name}
                    </option>
                  ))}
                </Input>
              </HStack>
              <Input
                name="contract_address"
                label="Contract Address"
                placeholder="Enter contract address"
                isDisabled={getTokenError}
              />
              <Box
                border="1px"
                borderColor="gray.400"
                mb="4"
                borderRadius="md"
                p="4"
              >
                <Heading fontSize="md">Token Holders</Heading>
                <FieldArray
                  name="holders"
                  render={(arrayHelpers) => (
                    <Box mt="4">
                      {values.holders.map((holder, index) => (
                        <HStack key={index} alignItems="flex-start">
                          <Input
                            label="Contract Address"
                            name={`holders.${index}.contract_address`}
                            isDisabled={getTokenError}
                            required
                          />
                          <Input
                            label="Distribution Address"
                            name={`holders.${index}.address`}
                            isDisabled={getTokenError}
                            required
                          />
                          <Input
                            component="select"
                            name={`holders.${index}.explorer`}
                            label="Explorer"
                            isDisabled={getTokenError}
                            required
                          >
                            <option value="scan.chiliz.com">
                              scan.chiliz.com
                            </option>
                            <option value="explorer.chiliz.com">
                              explorer.chiliz.com
                            </option>
                            <option value="api.etherscan.io">
                              api.etherscan.io
                            </option>
                            <option value="api.bscscan.com">
                              api.bscscan.com
                            </option>
                          </Input>

                          <Button onClick={() => arrayHelpers.remove(index)}>
                            X
                          </Button>
                        </HStack>
                      ))}
                      <Button
                        onClick={() =>
                          arrayHelpers.push({
                            contract_address: "",
                            address: "",
                            explorer: "scan.chiliz.com",
                          })
                        }
                      >
                        Add New Holder
                      </Button>
                    </Box>
                  )}
                />
              </Box>
              <FormControl w="full" mb="4">
                <FormLabel d="inline-flex" alignItems="center">
                  Country
                </FormLabel>
                <Field
                  name="country"
                  component={SelectField}
                  options={countries.map((country) => ({
                    label: country.name,
                    value: country.id,
                  }))}
                />
              </FormControl>
              <Input
                name="homepage"
                label="Influence Address"
                placeholder="Enter an URL"
                isDisabled={getTokenError}
              />
              <Input
                type="number"
                name="total_supply"
                label="Total Supply"
                placeholder="Enter total supply of token"
                isDisabled={getTokenError}
              />
              <Input
                type="number"
                name="test_supply"
                label="Test Supply"
                placeholder="Enter test supply of token"
                isDisabled={getTokenError}
              />
              <Input
                type="number"
                name="circulating_supply"
                label="Circulating Supply"
                placeholder="Enter circulating supply of token"
                isDisabled={getTokenError}
              />

              <Flex alignItems="center" justify="flex-end">
                {isEdit && (
                  <>
                    <Button
                      isLoading={deleteTokenMutation.isLoading}
                      isDisabled={
                        getTokenError ||
                        mutation.isLoading ||
                        deleteTokenMutation.isLoading
                      }
                      colorScheme="red"
                      mr="4"
                      onClick={() => setIsDeleteDialogOpen(true)}
                    >
                      Delete Token
                    </Button>
                    <AlertDialog
                      title={`Delete ${data.name}`}
                      message={`Are you sure you want to delete ${data.name}`}
                      isOpen={isDeleteDialogOpen}
                      onClose={() => setIsDeleteDialogOpen(false)}
                      onAction={() => deleteTokenMutation.mutate(slug)}
                    />
                  </>
                )}
                <Button
                  isLoading={mutation.isLoading}
                  isDisabled={
                    !isValid || getTokenError || mutation.isLoading || !dirty
                  }
                  type="submit"
                  colorScheme={getTokenError ? "red" : "blue"}
                >
                  {isEdit ? "Update" : "Create"}
                </Button>
              </Flex>
              {mutation.error && (
                <Alert
                  flexWrap="wrap"
                  variant="solid"
                  borderRadius="lg"
                  mr="4"
                  status="error"
                  mt="4"
                >
                  <AlertIcon mb="4" />
                  There was error while fetching fan token. You can't continue
                  with updating process. Send this message to #dev channel.
                  <Box mt="4">
                    <Code wordBreak="break-all">
                      {removeBearerFromString(
                        JSON.stringify(mutation.error, null, 2)
                      )}
                    </Code>
                  </Box>
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default CreateFanToken;
