import axios from "axios";
import { herokuURL } from "../config";

export const getPriceAlerts = async () => {
  const { data } = await axios.get(`${herokuURL}/price-alerts/findAllAsAdmin`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};
