import axios from "axios";
import { herokuURL } from "../config";

export const getApiKeys = async () => {
  const { data } = await axios.get(`${herokuURL}/api-key`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const getApiLogs = async (key) => {
  const { data } = await axios.get(`${herokuURL}/api-key/${key}`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const deleteApiKey = async (id) => {
  const { data } = await axios.delete(`${herokuURL}/api-key/${id}`);

  return data;
};

export const createApiKey = async (payload) => {
  const { data } = await axios.post(`${herokuURL}/api-key`, payload);

  return data;
};
