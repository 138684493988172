import React, { useState, useEffect, useContext, createContext } from "react";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { canAccess } from "../services/user.service";
import { useQuery } from "react-query";

const AuthContext = createContext({
  user: undefined,
});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const { refetch } = useQuery("canAccess", canAccess, {
    enabled: false,
    retry: false,
    onError: async () => await getAuth().signOut(),
  });

  useEffect(() => {
    const onTokenChange = async (user) => {
      if (!user) {
        setUser(null);
        localStorage.removeItem("token");
        return;
      }

      const token = await user.getIdToken();

      localStorage.removeItem("token");
      localStorage.setItem("token", token);

      axios.interceptors.request.use(
        function (config) {
          if (token != null) {
            config.headers.authorization = `Bearer ${token}`;
          }

          return config;
        },
        function (err) {
          return Promise.reject(err);
        }
      );

      await refetch().then(async ({ data }) => {
        if (data !== true) {
          await getAuth().signOut();
        }
      });

      setUser({
        email: user.email,
        displayName: user.displayName,
        uid: user.uid,
        emailVerified: user.emailVerified,
      });
    };

    return getAuth().onIdTokenChanged(onTokenChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handle = setInterval(async () => {
      const user = getAuth().currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
