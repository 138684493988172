import axios from "axios";
import { herokuURL } from "../config";

export const canAccess = async () => {
  const { data } = await axios.get(`${herokuURL}/user/adminize`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};
