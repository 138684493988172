import { Button, IconButton } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Box, Flex, Heading, HStack } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import dayjs from "dayjs";
import { useMemo } from "react";
import { RiAddBoxFill, RiEdit2Fill } from "react-icons/ri";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Table from "../../elements/table";
import { getTokensWithoutMarket } from "../../services/token.service";

function FanTokens() {
  const { data } = useQuery("getTokens", getTokensWithoutMarket);

  const columns = useMemo(
    () => [
      {
        Header: "Logo",
        accessor: "logo",
        otherProps: { textAlign: "center" },
        Cell: ({ value, row }) => {
          if (!value) {
            return null;
          }

          return (
            <Image
              w="32px"
              h="32px"
              src={value}
              mx="auto"
              alt={row.original.name}
            />
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Symbol",
        accessor: "symbol",
        otherProps: { textAlign: "right" },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => {
          return dayjs(value).format("DD MMM YYYY, HH:mm");
        },
        otherProps: { textAlign: "right", maxW: 100 },
      },
      {
        Header: "Actions",
        accessor: "slug",
        Cell: ({ value }) => {
          return (
            <HStack justify="flex-end">
              <Tooltip hasArrow placement="top" label="Edit" bg="red.400">
                <IconButton
                  as={Link}
                  to={`edit/${value}`}
                  size="sm"
                  variant="solid"
                  icon={<RiEdit2Fill />}
                />
              </Tooltip>
            </HStack>
          );
        },
        otherProps: { textAlign: "right" },
      },
    ],
    []
  );

  return (
    <Box>
      <Flex align="center">
        <Heading fontSize="lg">Fan Tokens</Heading>
        <Button
          as={Link}
          to="create"
          ml="4"
          variant="solid"
          size="sm"
          leftIcon={<RiAddBoxFill />}
        >
          Create new token
        </Button>
      </Flex>
      <Box mt="4">
        <Table
          columns={columns}
          data={data || []}
          initialState={{
            sortBy: [{ id: "created_at", desc: true }],
          }}
        />
      </Box>
    </Box>
  );
}

export default FanTokens;
