import { useRef, useState } from "react";
import { useField, useFormikContext } from "formik";
import { Box, Flex, Code } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { RiDeleteBin2Fill, RiUploadCloud2Fill } from "react-icons/ri";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";
import generateUUID, { removeBearerFromString } from "../utils";
import { Alert, AlertIcon } from "@chakra-ui/alert";

const FileUpload = ({
  placeholder,
  acceptedFileTypes,
  control,
  children,
  label,
  labelColor,
  required,
  isDisabled,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const inputRef = useRef();
  const [error, setError] = useState(false);

  const isInvalid = meta.touched && meta.error;

  const onSelectFile = (e) => {
    const storage = getStorage();

    e.preventDefault();
    if (!e.currentTarget.files || e.currentTarget.files.length < 1) {
      return;
    }

    const [file] = e.currentTarget.files;
    const storageRef = ref(storage, `tokens/${generateUUID()}`);

    uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(storageRef).then((downloadURL) => {
          setFieldValue(field.name, downloadURL);
        });
      })
      .catch((err) => {
        setError(JSON.stringify(err, null, 2));
      });
  };

  return (
    <Flex mb="4">
      {field.value && (
        <Box
          mr="6"
          bg="gray.100"
          p="4"
          boxShadow="lg"
          borderRadius="lg"
          maxW="120px"
        >
          <Image src={field.value} alt="Uploaded Image" />
        </Box>
      )}
      <FormControl
        isRequired={required}
        isInvalid={(meta.error && meta.touched) || false}
      >
        {label && (
          <FormLabel
            d="inline-flex"
            htmlFor={field.name}
            color={labelColor || "gray.600"}
            alignItems="center"
          >
            {label}
          </FormLabel>
        )}
        <Box>
          <input
            type="file"
            accept={acceptedFileTypes}
            name={field.name}
            ref={inputRef}
            style={{ display: "none" }}
            onChange={onSelectFile}
            disabled={field.value}
          ></input>
          <Button
            leftIcon={<RiUploadCloud2Fill />}
            onClick={() => inputRef.current.click()}
            isDisabled={field.value || isDisabled}
          >
            Upload Image
          </Button>
          {field.value && (
            <Button
              ml="4"
              colorScheme="red"
              leftIcon={<RiDeleteBin2Fill />}
              onClick={() => {
                setFieldValue(field.name, "");
              }}
            >
              Delete
            </Button>
          )}
          {isInvalid ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
        </Box>
      </FormControl>
      {error && (
        <Alert status="error" mt="4">
          <AlertIcon />
          Something went wrong. Please send this message to #development
          channel:
          <Box mt="4">
            <Code wordBreak="break-all">
              {removeBearerFromString(JSON.stringify(error, null, 2))}
            </Code>
          </Box>
        </Alert>
      )}
    </Flex>
  );
};

export default FileUpload;
