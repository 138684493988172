import { Box } from "@chakra-ui/layout";

const Container = ({ children, ...otherProps }) => {
  return (
    <Box
      w="full"
      maxW={{
        base: "full",
        sm: "full",
        md: "full",
        lg: "container.lg",
        xl: "container.xl",
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default Container;
