import React from "react";
import {
  NumberInput as ChakraInput,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Tooltip,
  Box,
  NumberInputField,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { RiInformationFill } from "react-icons/ri";

const NumberInput = ({
  label,
  type,
  component,
  children,
  labelColor,
  description,
  required,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const isInvalid = meta.touched && meta.error;

  return (
    <FormControl
      isRequired={required}
      isInvalid={isInvalid || false}
      w="full"
      mb="4"
    >
      {label && (
        <FormLabel
          d="inline-flex"
          htmlFor={field.name}
          color={labelColor || "gray.600"}
          alignItems="center"
        >
          {label}
          {description && (
            <Tooltip label={description} p="4" borderRadius="lg">
              <Box ml="1" color="gray.400" cursor="pointer">
                <Box boxSize="22px" as={RiInformationFill} />
              </Box>
            </Tooltip>
          )}
        </FormLabel>
      )}
      <ChakraInput
        id={field.name}
        aria-label={label || field.name}
        aria-describedby={label || field.name}
        type={type || "text"}
        isInvalid={isInvalid}
        bg="white"
        borderColor="gray.300"
        borderRadius="md"
        errorBorderColor="red.500"
        focusBorderColor="royalblue.500"
        {...field}
        {...props}
        onChange={(val) => setFieldValue(field.name, val)}
      >
        <NumberInputField
          placeholder={props.placeholder}
          w="full"
          pattern="(-)?[0-9]*(.[0-9]+)?"
        />
      </ChakraInput>
      {isInvalid ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default NumberInput;
