import { Box, Flex, Heading, Link } from "@chakra-ui/layout";
import { Button, ButtonGroup } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { RiCheckDoubleFill, RiCloseCircleLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import Table from "../../elements/table";
import { RiArrowDownLine, RiArrowUpLine } from "react-icons/ri";
import {
  changeBlogPostOrder,
  getBlogPosts,
  makeBlogPostFeatured,
  removeBlogPostFeatured,
  updateMediumPosts,
} from "../../services/blog.service";

function Blogs() {
  const { isError, isFetching, refetch } = useQuery(
    "getBlogPosts",
    getBlogPosts,
    {
      onSuccess: (data) => setInlineData(data),
    }
  );
  const [proccessingId, setProcessingId] = useState(null);
  const [inlineData, setInlineData] = useState([]);

  const makeMutation = useMutation("makeMutation", makeBlogPostFeatured);
  const removeMutation = useMutation("removeMutation", removeBlogPostFeatured);
  const updateMediumPostsMutation = useMutation(
    "updateMediumPosts",
    updateMediumPosts
  );
  const changeBlogPostOrderMutation = useMutation(
    "changeOrder",
    changeBlogPostOrder
  );

  const changeValuePosition = (arr, init, target) => {
    [arr[init], arr[target]] = [arr[target], arr[init]];
    return arr;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFeatured = (isFeatured, id) => {
    setProcessingId(id);
    if (!isFeatured) {
      makeMutation.mutateAsync(id).then(() => refetch());
    } else {
      removeMutation.mutateAsync(id).then(() => refetch());
    }
  };

  const handleRefresh = () => {
    updateMediumPostsMutation.mutateAsync().then(() => refetch());
  };

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        otherProps: { maxW: 200 },
        Cell: ({ value, row }) => {
          return (
            <Link href={row.original.url} color="blue" isExternal>
              {value}
            </Link>
          );
        },
      },
      {
        Header: "Published At",
        accessor: "published",
        otherProps: { maxW: 120 },
        Cell: ({ value }) => {
          return dayjs(value).format("DD MMM YYYY HH:mm");
        },
      },
      {
        Header: "Featured",
        accessor: "isFeatured",
        Cell: ({ value }) => {
          if (value === true) {
            return <RiCheckDoubleFill color="green" size="20px" />;
          } else {
            return <RiCloseCircleLine color="red" size="20px" />;
          }
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ value, row }) => {
          return (
            <ButtonGroup
              w="full"
              justifyContent="flex-end"
              size="sm"
              variant="outline"
              spacing="1"
            >
              <Button
                isLoading={
                  proccessingId === value &&
                  (makeMutation.isLoading ||
                    removeMutation.isLoading ||
                    isFetching)
                }
                onClick={() => handleFeatured(row.original.isFeatured, value)}
              >
                {row.original.isFeatured ? "Remove Featured" : "Make Featured"}
              </Button>
              {row.index !== 0 && (
                <Button
                  isLoading={changeBlogPostOrderMutation.isLoading}
                  onClick={() => {
                    const newData = changeValuePosition(
                      [...inlineData],
                      row.index,
                      row.index - 1
                    );

                    setInlineData([...newData]);

                    const value = newData.map((item, i) => ({
                      id: item._id,
                      order: i,
                    }));

                    changeBlogPostOrderMutation.mutate(value);
                  }}
                >
                  <RiArrowUpLine />
                </Button>
              )}
              {row.index !== inlineData.length - 1 && (
                <Button
                  isLoading={changeBlogPostOrderMutation.isLoading}
                  onClick={() => {
                    const newData = changeValuePosition(
                      [...inlineData],
                      row.index,
                      row.index + 1
                    );

                    setInlineData([...newData]);

                    const value = newData.map((item, i) => ({
                      id: item._id,
                      order: i,
                    }));

                    changeBlogPostOrderMutation.mutate(value);
                  }}
                >
                  <RiArrowDownLine />
                </Button>
              )}
            </ButtonGroup>
          );
        },
      },
    ],
    [
      changeBlogPostOrderMutation,
      handleFeatured,
      inlineData,
      isFetching,
      makeMutation.isLoading,
      proccessingId,
      removeMutation.isLoading,
    ]
  );

  if (isError) {
    return <p>Something went wrong... call help.</p>;
  }

  return (
    <Box>
      <Flex justify="space-between" align="center">
        <Heading fontSize="lg">Blog Posts</Heading>
        <Button
          size="sm"
          isLoading={updateMediumPostsMutation.isLoading}
          onClick={handleRefresh}
        >
          Refresh Blog Posts
        </Button>
      </Flex>
      <Box mt="4">
        <Table columns={columns} data={inlineData} />
      </Box>
    </Box>
  );
}

export default Blogs;
