import axios from "axios";
import { herokuURL } from "../config";

export const getBlogPosts = async () => {
  const { data } = await axios.get(`${herokuURL}/blog`, {
    // query URL without using browser cache
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return data;
};

export const makeBlogPostFeatured = async (id) => {
  const { data } = await axios.patch(`${herokuURL}/blog/make-featured/${id}`);

  return data;
};

export const removeBlogPostFeatured = async (id) => {
  const { data } = await axios.patch(`${herokuURL}/blog/remove-featured/${id}`);

  return data;
};

export const updateMediumPosts = async () => {
  const { data } = await axios.patch(`${herokuURL}/blog`);

  return data;
};

export const changeBlogPostOrder = async (newData) => {
  const { data } = await axios.patch(`${herokuURL}/blog/order`, {
    data: newData,
  });

  return data;
};
