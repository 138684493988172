import { Button } from "@chakra-ui/button";
import { Box, Flex, Heading } from "@chakra-ui/layout";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import Input from "../elements/input";
import { useState } from "react";
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router";

const LoginSchema = object().shape({
  email: string()
    .email("Email is not correct.")
    .required("This input is required."),
  password: string().required("This input is required"),
});

function Login() {
  const [user] = useAuthState(getAuth());
  const [error, setError] = useState(false);

  const signIn = async (values) => {
    if (!values.email || !values.password) return;

    setError(false);
    const auth = getAuth();

    await signInWithEmailAndPassword(auth, values.email, values.password)
      .then((user) => {
        // window.location = "/";
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Flex
      flexDir="column"
      minH="100vh"
      align="center"
      justify="center"
      bg="gray.50"
      px={{ base: 4 }}
    >
      <Box
        bg="white"
        px={{ base: "4", lg: "12" }}
        py={{ base: "4", lg: "12" }}
        boxShadow="sm"
        borderRadius="lg"
      >
        <Heading size="lg" mb="4">
          Login
        </Heading>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={signIn}
        >
          {({ isSubmitting }) => (
            <Form>
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="Enter your email address"
              />
              <Input
                type="password"
                name="password"
                label="Password"
                placeholder="Enter your password"
              />
              <Button
                isLoading={isSubmitting}
                type="submit"
                w="full"
                colorScheme="blue"
                size="lg"
              >
                Log in
              </Button>

              {error && (
                <Alert status="error" mt="4">
                  <AlertIcon />
                  Something went wrong.
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}

export default Login;
