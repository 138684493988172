import { Alert, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Box, Code, Flex, Heading } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { object, string } from "yup";
import Input from "../../elements/input";
import { createApiKey } from "../../services/api-key.service";
import { removeBearerFromString } from "../../utils";

const apiKeySchema = object().shape({
  owner: string().required("Owner is required"),
  contactAddress: string().email().required("Contact address is required"),
});

const initialValues = {
  owner: "",
  contactAddress: "",
};

function CreateAPIKey() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const mutation = useMutation("createApiKey", createApiKey);

  return (
    <Box>
      <Heading fontSize="lg">Create New Api Key</Heading>

      <Box mt="6">
        <Formik
          initialValues={initialValues}
          validationSchema={apiKeySchema}
          onSubmit={async (values) => {
            await mutation.mutateAsync(values).then(() => {
              queryClient.invalidateQueries("getApiKeys");
              toast({
                title: "Api key is created.",
                description: `We've created api key successfully.`,
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "top",
              });
              navigate("/dashboard/api-keys");
            });
          }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Input
                name="owner"
                label="Owner"
                placeholder="Enter owner information"
              />

              <Input
                name="contactAddress"
                label="Contact Address"
                placeholder="Enter contact address (email)"
              />

              <Flex alignItems="center" justify="flex-end">
                <Button
                  isLoading={mutation.isLoading}
                  isDisabled={!isValid || mutation.isLoading || !dirty}
                  type="submit"
                  colorScheme="blue"
                >
                  Create
                </Button>
              </Flex>
              {mutation.error && (
                <Alert
                  flexWrap="wrap"
                  variant="solid"
                  borderRadius="lg"
                  mr="4"
                  status="error"
                  mt="4"
                >
                  <AlertIcon mb="4" />
                  There was error while creating api key. You can't continue
                  with updating process. Send this message to #development
                  channel.
                  <Box mt="4">
                    <Code wordBreak="break-all">
                      {removeBearerFromString(
                        JSON.stringify(mutation.error, null, 2)
                      )}
                    </Code>
                  </Box>
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default CreateAPIKey;
