import { Route, Routes } from "react-router";
import Login from "./pages/login";
import DashboardLayout from "./elements/dashboard-layout";
import FanTokens from "./pages/dashboard/fan-tokens";
import CreateFanToken from "./pages/dashboard/create-fan-token";
import Dashboard from "./pages/dashboard/dashboard";
import PriceAlerts from "./pages/dashboard/price-alerts";
import APIKeys from "./pages/dashboard/api-keys";
import CreateAPIKey from "./pages/dashboard/create-api-key";
import ApiKeyLogs from "./pages/dashboard/api-key-logs";
import Blogs from "./pages/dashboard/blogs";
import { MakeAdmin } from "./pages/dashboard/make-admin";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route path="fan-tokens">
            <Route index element={<FanTokens />} />
            <Route path="create" element={<CreateFanToken />} />
            <Route path="edit/:slug" element={<CreateFanToken isEdit />} />
          </Route>
          <Route path="price-alerts">
            <Route index element={<PriceAlerts />} />
          </Route>
          <Route path="api-keys">
            <Route index element={<APIKeys />} />
            <Route path="create" element={<CreateAPIKey />} />
            <Route path=":apiKey" element={<ApiKeyLogs />} />
          </Route>
          <Route path="blogs">
            <Route index element={<Blogs />} />
          </Route>
          <Route path="users">
            <Route path="adminize" index element={<MakeAdmin />} />
          </Route>
          <Route index element={<Dashboard />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
