import { Box, Flex, Text } from "@chakra-ui/layout";
import NavItem from "./nav-item";
import {
  RiNotificationFill,
  RiCopperDiamondFill,
  RiDashboard2Fill,
  RiKey2Fill,
  RiRssFill,
  RiUser2Fill,
} from "react-icons/ri";
import { Image } from "@chakra-ui/image";

function SidebarContent(props) {
  const menuItems = [
    {
      icon: RiDashboard2Fill,
      href: "/dashboard",
      label: "Dashboard",
    },
    {
      icon: RiCopperDiamondFill,
      href: "/dashboard/fan-tokens",
      label: "Fan Tokens",
    },
    {
      icon: RiNotificationFill,
      href: "/dashboard/price-alerts",
      label: "Price Alerts",
    },
    {
      icon: RiKey2Fill,
      href: "/dashboard/api-keys",
      label: "API Keys",
    },
    {
      icon: RiRssFill,
      href: "/dashboard/blogs",
      label: "Blogs",
    },
    {
      icon: RiUser2Fill,
      href: "/dashboard/users/adminize",
      label: "Admins",
    },
  ];

  return (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="purple.900"
      color="white"
      w="72"
      boxShadow="md"
      {...props}
    >
      <Flex px="4" py="5" align="center">
        <Text fontSize="2xl" ml="2" fontWeight="semibold">
          GM{" "}
        </Text>
        <Image
          ml="4"
          maxW="42px"
          src={process.env.PUBLIC_URL + "/images/sun-with-face.png"}
        />
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        {menuItems.map((item) => {
          return (
            <NavItem key={item.href} href={item.href} icon={item.icon}>
              {item.label}
            </NavItem>
          );
        })}
      </Flex>
    </Box>
  );
}

export default SidebarContent;
