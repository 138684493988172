import { extendTheme } from "@chakra-ui/react";

export const defaultDarkBg = "gray.900";

const customTheme = extendTheme({
  fonts: {
    heading:
      'Poppins, -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    body: 'Poppins, -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  colors: {
    blue: {
      50: "#e2efff",
      100: "#b2cbff",
      200: "#80a4ff",
      300: "#4f7afc",
      400: "#1f4cfa",
      500: "#0542e0",
      600: "#003fb0",
      700: "#00357f",
      800: "#00254f",
      900: "#001020",
    },
  },
  sizes: {
    container: {
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
    },
  },
  config: {
    cssVarPrefix: "fmc",
  },
});

export default customTheme;
